<template>
  <div>
    <b-card>
      <b-card-title>
        {{ $t('CV') }}
      </b-card-title>
      <b-card-body>
        <b-row align-h="center">

          <b-col
            cols="12"
            lg="4"
          >
            <b-avatar
              size="120px"
              :src="require('@/assets/images/portrait/small/avatar-s-20.jpg')"
            />
          </b-col>
          <b-col
            cols="12"
            lg="4"
            class="mt-2"
          >
            <h1> {{ UserData.first_name +' '+ UserData.last_name }}</h1>
            <h6>{{ UserData.qualifications }}</h6>
          </b-col>
          <b-col
            cols="12"
            lg="4"
            class="mt-2"
          >
            <b-row>
              <b-col cols="12">
                <fa-icon
                  class="mr-1"
                  color="primary"
                  pack="fas"
                  icon="phone"
                />
                <span>{{ UserData.phone }}</span>
              </b-col>
              <b-col cols="12">
                <fa-icon
                  class="mr-1"
                  color="primary"
                  pack="fas"
                  icon="map-marker"
                />
                <span>{{ UserData.address }}</span>
              </b-col>
              <b-col cols="12">
                <fa-icon
                  class="mr-1"
                  color="primary"
                  pack="fas"
                  icon="envelope-square"
                />
                <span>{{ UserData.email }}</span>
              </b-col>
            </b-row>

          </b-col>
          <b-col
            cols="12"
            class="mt-1 mb-1"
          >
            <hr>
          </b-col>
          <b-col
            cols="12"
          >
            <h2>Profile Summary</h2>
            <p
              class="mt-2 mb-2"
            >
              {{ UserData.profile_summary }}
            </p>
          </b-col>
          <b-col
            cols="12"
            class="mt-1 mb-1"
          >
            <hr>
          </b-col>
          <b-col
            cols="12"
            lg="8"
          >
            <b-row>

              <b-col
                cols="12"
                class="card_border mb-2"
              >
                <h2>Experience</h2>
                <b-row
                  v-for="(tr, indextr) in UserData.experiences"
                  :key="indextr"
                  class="mt-2 mb-2 item"
                >
                  <b-col
                    cols="12"
                    class="mb-1"
                    lg="6"
                  >
                    <h5><b>{{ tr.title }}</b></h5>
                    <span>{{ tr.description }}</span>
                  </b-col>
                  <b-col
                    cols="12"
                    class="mb-1"
                    lg="6"
                  >
                    <b-row>
                      <b-col
                        cols="12"
                        lg="6"
                      >
                        <vx-badge
                          v-if="tr.from && tr.to"
                          color="light-primary"
                        >
                          {{ tr.from }} - {{ tr.to }}
                        </vx-badge>
                        <vx-badge
                          v-else-if="tr.from"
                          color="light-primary"
                        >
                          {{ tr.from }}
                        </vx-badge>
                        <vx-badge
                          v-else
                          color="light-primary"
                        >
                          No Date specified
                        </vx-badge>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                cols="12"
                class="mt-1 mb-1"
              >
                <hr>
              </b-col>
              <b-col
                cols="12"
                class="card_border"
              >
                <h2>Education</h2>
                <b-row
                  v-for="(tr, indextr) in UserData.educations"
                  :key="indextr"
                  class="mt-2 mb-2 item"
                >
                  <b-col
                    cols="12"
                    class="mb-1"
                    lg="6"
                  >
                    <h5><b>{{ tr.title }}</b></h5>
                    <span>{{ tr.institution }}</span>
                  </b-col>
                  <b-col
                    cols="12"
                    class="mb-1"
                    lg="6"
                  >
                    <vx-badge
                      v-if="tr.start && tr.finish"
                      color="light-primary"
                    >
                      {{ tr.start }} - {{ tr.finish }}
                    </vx-badge>
                    <vx-badge
                      v-else-if="tr.start"
                      color="light-primary"
                    >
                      {{ tr.start }}
                    </vx-badge>
                    <vx-badge
                      v-else
                      color="light-primary"
                    >
                      No Date specified
                    </vx-badge>
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                cols="12"
                class="mt-1 mb-1"
              >
                <hr>
              </b-col>
              <b-col
                cols="12"
                class="card_border"
              >
                <h2>Volunteering Work</h2>
                <b-row
                  v-for="(tr, indextr) in UserData.volunteering_works"
                  :key="indextr"
                  class="mt-2 mb-2 item"
                >
                  <b-col
                    cols="12"
                    class="mb-1"
                    lg="6"
                  >
                    <h5><b>{{ tr.title }}</b></h5>
                    <span>{{ tr.description }}</span>
                  </b-col>
                  <b-col
                    cols="12"
                    class="mb-1"
                    lg="6"
                  >
                    <vx-badge
                      v-if="tr.from && tr.to"
                      color="light-primary"
                    >
                      {{ tr.from }} - {{ tr.to }}
                    </vx-badge>
                    <vx-badge
                      v-else-if="tr.from"
                      color="light-primary"
                    >
                      {{ tr.from }}
                    </vx-badge>
                    <vx-badge
                      v-else
                      color="light-primary"
                    >
                      No Date specified
                    </vx-badge>
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                cols="12"
                class="mt-1 mb-1"
              >
                <hr>
              </b-col>
              <b-col
                cols="12"
                class="card_border"
              >
                <h2>Additional Information</h2>
                <p class="item">
                  {{ UserData.additional_information }}
                </p>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            cols="12"
            lg="4"
          >
            <b-row>
              <b-col
                cols="12"
              >
                <h2>Interests</h2>
                <b-row
                  class="mt-2 mb-2"
                >
                  <b-col
                    v-for="(tr, indextr) in UserData.interests"
                    :key="indextr"
                    cols="4"
                    class="mt-1"
                  >
                    <vx-badge
                      color="light-success"
                    >
                      {{ tr.title }}
                    </vx-badge>
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                cols="12"
                class="mt-1 mb-1"
              >
                <hr>
              </b-col>
              <b-col
                cols="12"
              >
                <h2>Desired Jobs</h2>
                <b-row class="mt-2 mb-2">
                  <b-col
                    v-for="(tr, indextr) in UserData.occupations"
                    :key="indextr"
                    cols="4"
                  >
                    <vx-badge
                      color="light-success"
                    >
                      {{ tr.title }}
                    </vx-badge>
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                cols="12"
                class="mt-1 mb-1"
              >
                <hr>
              </b-col>
              <b-col
                cols="12"
              >
                <h2>Languages</h2>
                <b-row
                  v-for="(tr, indextr) in UserData.languages"
                  :key="indextr"
                  class="mt-2 mb-2"
                >
                  <b-col
                    cols="6"
                  >
                    <vx-badge
                      color="light-success"
                    >
                      {{ tr.language }}
                    </vx-badge>
                  </b-col>
                  <b-col
                    cols="6"
                  >
                    {{ tr.status }}
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                cols="12"
                class="mt-1 mb-1"
              >
                <hr>
              </b-col>
              <b-col
                cols="12"
              >
                <h2>Other</h2>
                <b-row class="mt-2 mb-2">
                  <b-col cols="6">
                    <vx-badge
                      color="light-success"
                    >
                      Work Premit
                    </vx-badge>
                  </b-col>
                  <b-col cols="6">
                    <fa-icon
                      v-if="UserData.work_permit"
                      pack="fas"
                      icon="check"
                      color="success"
                    />
                    <fa-icon
                      v-else
                      pack="fas"
                      icon="times"
                      color="danger"
                    />
                  </b-col>
                  <b-col
                    class="mt-1"
                    cols="6"
                  >
                    <vx-badge
                      color="light-success"
                    >
                      Refugee Status
                    </vx-badge>
                  </b-col>
                  <b-col
                    class="mt-1"
                    cols="6"
                  >
                    <fa-icon
                      v-if="UserData.refugee_status"
                      pack="fas"
                      icon="check"
                      color="success"
                    />
                    <fa-icon
                      v-else
                      pack="fas"
                      icon="times"
                      color="danger"
                    />
                  </b-col>
                  <b-col
                    class="mt-1"
                    cols="6"
                  >
                    <vx-badge
                      color="light-success"
                    >
                      Employment Type
                    </vx-badge>
                  </b-col>
                  <b-col
                    class="mt-1"
                    cols="6"
                  >
                    <vx-badge color="light-success">
                      {{ UserData.employment_type }}
                    </vx-badge>
                  </b-col>
                  <b-col
                    class="mt-1"
                    cols="6"
                  >
                    <vx-badge
                      color="light-success"
                    >
                      Availability
                    </vx-badge>
                  </b-col>
                  <b-col
                    class="mt-1"
                    cols="6"
                  >
                    <vx-badge :color="UserData.availability==='available'?'light-success':'light-danger'">
                      {{ UserData.availability }}
                    </vx-badge>
                  </b-col>
                  <b-col
                    class="mt-1"
                    cols="6"
                  >
                    <vx-badge
                      color="light-success"
                    >
                      Desired Job Location
                    </vx-badge>
                  </b-col>
                  <b-col
                    class="mt-1"
                    cols="6"
                  >
                    <vx-badge color="light-success">
                      {{ UserData.job_address }}
                    </vx-badge>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-row>
      <b-col
        cols="12"
      >
        <b-card>
          <b-card-title>
            {{ $t('Devices') }}
          </b-card-title>
          <b-card-body>
            <vs-table
              stripe
              :data="[]"
              no-data-text=""
            >
              <template slot="thead">
                <vs-th>{{ $t('Browser') }}</vs-th>
                <vs-th>{{ $t('Device') }}</vs-th>
                <vs-th>{{ $t('Device Type') }}</vs-th>
                <vs-th>{{ $t('platform') }}</vs-th>
                <vs-th>{{ $t('Logged in At') }}</vs-th>
              </template>
              <template slot-scope="{}">
                <vs-tr
                  v-for="(tr, indextr) in UserData.devices"
                  :key="indextr"
                >
                  <vs-td :data="tr.browser">
                    <img
                      v-if="tr.browser_image"
                      width="20px"
                      :src="require(`@/assets/images/browsers/${tr.browser_image}.png`)"
                    >
                  </vs-td>
                  <vs-td :data="tr.device">
                    {{ tr.device }}
                  </vs-td>
                  <vs-td :data="tr.device_type">
                    <fa-icon
                      color="primary"
                      pack="fas"
                      :icon="tr.device_type_icon"
                    />
                  </vs-td>
                  <vs-td :data="tr.platform">
                    <fa-icon
                      color="primary"
                      pack="fab"
                      :icon="tr.platform_icon"
                    />
                  </vs-td>
                  <vs-td :data="tr.created_at">
                    <VxTooltip
                      color="success"
                    >
                      <template #title>
                        {{ $t('Created_At') }}
                      </template>
                      <template #text>
                        {{ tr.created_at }}
                      </template>
                      <vx-badge
                        pack="far"
                        icon="calendar-plus"
                        color="light-success"
                        icon-color-bg="success"
                        icon-color="null"
                      >
                        {{ $t(tr.created_from) }}
                      </vx-badge>
                    </VxTooltip>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  /* eslint-disable */
  data:()=>({
  }),
  methods:{
  },
  computed:{
    UserData(){
      return this.$store.getters['employee/person']
    }
  },
  mounted(){
  },
  created() {
    this.$store.dispatch('employee/GetPersonData',this.$route.params.userId)
  }
}
</script>
<style scoped>

.card_border .item {
  border: 1px solid transparent;
  padding: 10px;
}
.card_border .item:hover {
  border-radius: 10px;
  border: 1px solid orange;
}
</style>